const faqs = [
  {
    question: "¿Qué debo verificar?",
    answer: (
      <div>
        <p>1. Verificá la fecha de expiración de tu pasaporte actual. Si tu pasaporte ha expirado o está por expirar en los próximos 6 meses, debes renovarlo para poder viajar. <br/> <br/>

2. Reuní los documentos necesarios:  <br/> <br/>
- Ciudadanía Italiana. <br/> <br/>
- DNI vigente con una foto actualizada o una fotocopia del mismo. <br/> <br/>
- Su antiguo pasaporte con una fotocopia de sus tres primeras páginas. <br/> <br/>
- 2 Fotografías tipo carnet (35x40mm) con un fondo blanco y mirando hacia adelante.  <br/> <br/>
- Comprobante del trámite una vez ya pagado y realizado tres días antes del turno.  <br/> <br/>
Si decidís renovar tu pasaporte en línea, enviá los documentos necesarios por correo postal al consulado o embajada italiana correspondiente. Si deseas renovar tu pasaporte en persona, llevá los documentos necesarios contigo a la cita.

</p>
      </div>
    ),
  },
  {
    question: "¿Dónde sacar el turno para el pasaporte italiano?",
    answer: (
      <div>
        <p>La renovación del pasaporte italiano en Argentina solo puede realizarse a través del Consulado italiano, el cual se ubica en la Ciudad Autónoma de Buenos Aires, pero también cuenta con representaciones en diversas provincias para la atención de acuerdo al domicilio del solicitante: <br/> <br/> - Morón<br/> <br/> 
- Rosario<br/> <br/> 
- La Plata<br/> <br/> 
- Córdoba<br/> <br/> 
- Bahía Blanca<br/> <br/> 
- Mar de Plata<br/> <br/> 
- Buenos Aires<br/> <br/> 
- Lomas de Zamora

 </p> 

      </div>
    ),
  },
  {
    question: "¿Cuánto tiempo se tarda en la renovación del pasaporte italiano?",
    answer: (
      <div>
        <p>
        El proceso de renovación del pasaporte italiano puede variar significativamente según varios factores, aunque lo ideal es que lleve entre 2 a 4 meses desde que se solicita el primer turno.  Es necesario que tengas en cuenta que:  <br/> <br/> - La disponibilidad de turnos virtuales puede ser inmediata o demorar varios meses, según la disponibilidad del consulado. <br/> <br/>
- El proceso en su mayoría es virtual, por lo que requiere esperar revisiones y seguir indicaciones específicas antes de pagar y asistir personalmente en la fecha programada para recoger el pasaporte renovado.<br/> <br/>En resumen, renovar el pasaporte italiano es un proceso sencillo que se puede hacer en línea o en persona en el consulado o embajada italiana más cercana a tu ubicación. Asegúrate de contar con los documentos necesarios y de programar tu cita con anticipación para evitar contratiempos en tus planes de viaje. ¡Buon viaggio!
 
        </p> 
      </div>
    ),
  },

];

export default faqs;
